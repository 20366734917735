import styled from 'styled-components'

export const FavouriteWrapperStyled = styled.div.attrs((props) => ({}))`
    //text-align:center;
`

export const FavouriteSvgStyled = styled.svg.attrs((props) => ({}))`
    width: 22px;
`

interface FavouriteBespokeIconProperties {
    active: boolean
    iconColor?: string
}

export const FavouriteBespokeIconStyled = styled.i.attrs((props: FavouriteBespokeIconProperties) => ({}))<FavouriteBespokeIconProperties>`
    color: ${(props) => {
        if (props.active) {
            return props.iconColor ? props.iconColor : 'var(--negative)'
        }
        return props.iconColor ? props.iconColor : 'var(--column-color)'
    }};
`
