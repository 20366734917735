import styled from 'styled-components'

export const SpinnerPlacementStyled = styled.div.attrs((props) => ({}))`
    position: relative;
    z-index: 4000;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    top: -20px;
`
