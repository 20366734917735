import styled from 'styled-components'

export const SecondaryOttoNavContainerStyled = styled.div.attrs((props) => ({
    className: '',
    id: 'secondary-nav-container',
}))`
    padding-top: 10px;
    @media (max-width: 992px) {
        max-height: 100px;
        overflow: auto;
    }
    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }

    ::-webkit-scrollbar-track {
        background-color: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(111, 75, 242, 1);

        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgba(111, 75, 242, 1);
    }
`

export const SecondaryOttoNavContentsStyled = styled.div.attrs((props) => ({
    // className: 'd-flex flex-wrap align-items-center justify-content-center justify-content-lg-start pt-2 pb-2',
    id: 'secondary-nav-contents',
}))`
    width: calc(100vw - 280px);
    padding-bottom: 10px;
`

export const SecondaryOttoNavSearchContainerStyled = styled.div.attrs((props) => ({
    id: 'search-container',
}))`
    width: 230px;
    height: 45px;
    position: absolute;
    top: 21px;
    right: 10px;
`

export const SecondaryTabsStyled = styled.div.attrs((props) => ({
    className: ' col-12 col-lg-auto me-lg-auto mb-2 d-flex justify-content-start align-items-baseline mb-md-0',
    id: 'secondary-nav-tabs',
}))``

export const SecondaryTabTitleStyled = styled.div.attrs((props) => ({
    className: ' nav-link px-2 fs-4 text-white',
    id: 'secondary-nav-title',
}))``

interface SecondaryTabProperties {
    active: boolean
}

export const SecondaryTabStyled = styled.div.attrs((props: SecondaryTabProperties) => ({
    className: 'otto-nav-link nav-link px-2 ' + (props.active ? 'active' : ''),
}))<SecondaryTabProperties>``
export const SecondaryUIStyled = styled.ul.attrs((props) => ({}))`
    @media (min-width: 992px) {
        list-style-type: none;
        margin: 0;
        padding: 0;
        //position: absolute;
    }
    @media (max-width: 992px) {
        display: block;
        float: none;
    }
`
export const SecondaryLIStyled = styled.li.attrs((props) => ({}))`
    @media (min-width: 992px) {
        display: inline-block;
        float: left;
        margin-right: 1px;
    }

    @media (max-width: 992px) {
        display: block;
        float: none;
    }
`
