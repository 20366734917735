import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import { useAppDispatch } from '../../../../app/hooks'
import { DraggableModalDialog } from '../../draggable-modal/draggable-modal'
import { clearAllRowSelections } from '../../gusl-table/guslTableSlice'
import ActionButton from '../ActionButton'

interface ElementWithModalProps extends ModalProps {
    element: React.JSX.Element
    modalContent: React.JSX.Element
    header?: string | React.JSX.Element
    withCancel?: boolean
    withOkay?: boolean
    close?: boolean
    open?: boolean
    refresher?: string
    hideHeader?: boolean
    onCloseHandler?: Function
    modalClassName?: string
    code?: string | undefined
}

export default function ElementWithModal({
    code,
    element,
    modalContent,
    header,
    size,
    fullscreen,
    withCancel,
    withOkay,
    close,
    open,
    refresher,
    hideHeader = false,
    centered = false,
    onCloseHandler,
    modalClassName = '',
}: ElementWithModalProps) {
    const dispatch = useAppDispatch()

    const [className] = useState<string>('ElementWithModal-' + new Date().getTime())

    const [show, setShow] = useState(false)

    const handleClose = () => {
        console.log('---------- handle close ----- ')
        setShow(false)
        if (onCloseHandler) {
            onCloseHandler()
        }
    }
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (close) {
            handleClose()
        }
    }, [close, refresher])
    useEffect(() => {
        if (open) {
            handleShow()
        }
    }, [open])

    // if(close) handleClose();
    return (
        <div key={'element_with_modal_' + code}>
            <span role={'button'} data-bs-toggle="modal" data-bs-target={'#modal'} onClick={handleShow}>
                {element}
            </span>

            <Modal
                className={modalClassName}
                key={'modal_' + code}
                show={show}
                dialogAs={DraggableModalDialog}
                centered={centered}
                onHide={handleClose}
                size={size ? size : 'lg'}
                fullscreen={fullscreen}
                scrollable
            >
                {!hideHeader && (
                    <Modal.Header key={'modal_hdr_' + code} closeButton className={'text-light draggable-dialog-header'}>
                        {header}{' '}
                    </Modal.Header>
                )}

                <Modal.Body key={'modal_body_' + code}>{modalContent}</Modal.Body>
                {(withCancel || withOkay) && (
                    <Modal.Footer className={'d-flex justify-content-center border-0'} key={'modal_footer_' + code}>
                        {withOkay && (
                            <ActionButton
                                action={'ok'}
                                label={'Ok'}
                                onClick={() => {
                                    setShow(false)
                                }}
                            />
                        )}
                        {withCancel && (
                            <ActionButton
                                action={'cancel'}
                                label={'Cancel'}
                                onClick={() => {
                                    if (code) {
                                        dispatch(clearAllRowSelections({ code: code }))
                                    }
                                    handleClose()
                                    // setShow(false);
                                }}
                            />
                        )}
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    )
}
