import React, { useState } from 'react'
import { log } from '../../../../services/LogService'

export interface MoneyWithCurrencyDO {
    value: number
    currency: string
}

export default function MoneyWithCurrency({ value, currency }: MoneyWithCurrencyDO) {
    const [className] = useState('MoneyWithCurrency-' + new Date().getTime())

    return (
        <div className={'d-flex align-items-center justify-content-start'}>
            <span>{value ?? '---'}</span>
            <>&nbsp;</>
            <span>{value ? currency : <>&nbsp;</>}</span>
        </div>
    )
}
