import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalSearch, GlobalSearchProperties } from '../../../common/global-search/GlobalSearch'
import ArticleModal from '../article-modal/ArticleModal'
import { OTTO_HOME } from '../OttoEvents'
import { OttoBoxStyled } from '../styled'
import {
    SecondaryLIStyled,
    SecondaryOttoNavContainerStyled,
    SecondaryOttoNavContentsStyled,
    SecondaryOttoNavSearchContainerStyled,
    SecondaryTabsStyled,
    SecondaryTabStyled,
    SecondaryUIStyled,
} from './styled'

export interface SecondaryOttoNavDO {
    // setCurrentTab: Function;
    // currentTab: string;
}

// if change, all change OttoTable
export const HOME = 'HOME'

export const RISK_ARB = 'RISK ARBITRAGE'
export const HOLDCO = 'HOLDCO'
export const SHARE_CLASS = 'SHARE CLASS'
export const PRE_EVENT = 'PRE EVENT'
export const GENERAL = 'GENERAL'
export const ALL_EVENT = 'EVENT CALENDAR'
export const OTHER = 'OTHER SPECIAL SITS'

export const SPIN_OFFS = 'SPIN OFFS'

export const RIGHTS_ISSUE = 'RIGHTS ISSUE'

export const RUMOURS = 'RUMOURS'

export const REGULATION = 'REGULATION'

export const getTab = (fullname: string | undefined) => {
    if (!fullname) {
        return 'HOME'
    }

    if (fullname.toUpperCase() === 'HOME') {
        return 'HOME'
    } else if (fullname.toUpperCase() === 'RISK ARBITRAGE') {
        return 'RISK_ARB'
    } else if (fullname.toUpperCase() === 'HOLDCO') {
        return 'HOLDCO'
    } else if (fullname.toUpperCase() === 'SHARE CLASS') {
        return 'SHARE_CLASS'
    } else if (fullname.toUpperCase() === 'PRE EVENT') {
        return 'PRE_EVENT'
    } else if (fullname.toUpperCase() === 'EVENT CALENDAR') {
        return 'ALL_EVENT'
    } else if (fullname.toUpperCase() === 'OTHER SPECIAL SITS') {
        return 'OTHER'
    } else {
        return 'HOME'
    }
}

export const getTabCode = (tabParam: string | undefined) => {
    if (!tabParam) {
        return HOME
    }
    let returnVal = HOME
    if (tabParam.toLowerCase() === 'HOME'.toLowerCase()) {
        returnVal = HOME
    } else if (tabParam.toLowerCase() === 'RISK_ARB'.toLowerCase()) {
        returnVal = RISK_ARB
    } else if (tabParam.toLowerCase() === 'HOLDCO'.toLowerCase()) {
        returnVal = HOLDCO
    } else if (tabParam.toLowerCase() === 'SHARE_CLASS'.toLowerCase()) {
        returnVal = SHARE_CLASS
    } else if (tabParam.toLowerCase() === 'PRE_EVENT'.toLowerCase()) {
        returnVal = PRE_EVENT
    } else if (tabParam.toLowerCase() === 'OTHER'.toLowerCase()) {
        returnVal = OTHER
    } else if (tabParam.toLowerCase() === 'ALL_EVENT'.toLowerCase()) {
        returnVal = ALL_EVENT
    }
    return returnVal
}

export const SECONDARY_TABS: string[] = [HOME, RISK_ARB, PRE_EVENT, HOLDCO, SHARE_CLASS, OTHER, ALL_EVENT]
export default function SecondaryOttoNav() {
    // {setCurrentTab, currentTab}: SecondaryOttoNavDO
    const navigate = useNavigate()
    const params = useParams()

    const tabParam = params?.tab ? decodeURIComponent(params?.tab) : undefined

    const [activeTab, setActiveTab] = useState<string>(() => getTabCode(tabParam))

    const renderResultDialog = (response: any): React.ReactElement => {
        // return <ArticleModal articleId={response.articleId} routeUrl={response.path} routeLabel={'View Deal'} />
        return <ArticleModal articleId={response.articleId} result={response} />
    }

    const [globalSearchProperties] = useState<GlobalSearchProperties>(() => {
        return {
            code: 'otto',
            selectUrl: '/otto-articles/global-search',
            renderResultDialog: renderResultDialog,
        }
    })

    const onTabClick = (e: React.MouseEvent<HTMLLIElement>, tabCode: string) => {
        // const onTabClick = (e: React.MouseEvent<HTMLDivElement>, tabCode: string) => {
        if (e) {
            e.stopPropagation()
        }
        setActiveTab(tabCode)
        navigate(OTTO_HOME + '/' + getTab(tabCode))
    }

    const renderTabs = (): React.ReactElement => {
        return (
            <SecondaryUIStyled>
                {SECONDARY_TABS.map((tabCode, idx) => (
                    <SecondaryLIStyled key={tabCode} onClick={(e) => onTabClick(e, tabCode)}>
                        <SecondaryTabStyled active={tabCode === activeTab}>{tabCode}</SecondaryTabStyled>
                    </SecondaryLIStyled>
                ))}
            </SecondaryUIStyled>
        )
    }

    /*
                        <div key={tabCode} onClick={(e) => onTabClick(e, tabCode)}>
                            <SecondaryTabStyled active={tabCode === activeTab}>{tabCode}</SecondaryTabStyled>
                        </div>
    
     */

    const renderSearch = (): React.ReactElement => {
        return <GlobalSearch {...globalSearchProperties} />
    }

    return (
        <SecondaryOttoNavContainerStyled>
            <OttoBoxStyled>
                <SecondaryOttoNavContentsStyled>
                    <SecondaryTabsStyled>{renderTabs()}</SecondaryTabsStyled>
                    <div className="text-end"></div>
                </SecondaryOttoNavContentsStyled>
                <SecondaryOttoNavSearchContainerStyled>{renderSearch()}</SecondaryOttoNavSearchContainerStyled>
            </OttoBoxStyled>
        </SecondaryOttoNavContainerStyled>
    )
}
