import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { GuslThemeContext } from '../../../../providers/theme/GuslThemeProvider'
import { cancelAbortController, RunOnceEffect } from '../../../../utils/Utils'
import { ActionDialogHeaderStyled, ActionDialogTitleStyled } from '../../../common/action-dialog/styled'
import { DraggableModalDialog } from '../../../common/draggable-modal/draggable-modal'
import { closeGlobalSearchResults, closeResultDialog } from '../../../common/global-search/GlobalSearchSlice'
import {
    ArticleResultWrapperStyled,
    ClosedStyled,
    ResultTitleStyled,
    ResultTitleWrapperStyled,
} from '../../../common/global-search/results/styled'
import { GlobalSearchResponseDO } from '../../../common/global-search/type'
import TableSpinner from '../../../common/gusl-table/spinner/TableSpinner'
import { ImageStyled } from '../../../common/image/styled'
import Icon from '../../../common/maintain-table/bootstrap/Icon'
import { WysiwygTableContentStyled } from '../../../common/wysiwyg/styled'
import DealTypeLabel from '../articles-view/DealTypeLabel'
import { ArticleInlineDateStyled, GoToRouteStyled } from '../articles-view/styled'
import {
    ArticleFooterColumnContentsStyled,
    ArticleFooterColumnDetailsStyled,
    ArticleFooterColumnDetailStyled,
    ArticleFooterColumnHeaderStyled,
    ArticleFooterColumnStyled,
    ArticleFooterGridStyled,
    ArticleFooterGridWrapperStyled,
    ArticleFooterRowStyled,
    ArticleFooterStyled,
    ArticleViewDialogBodyStyled,
    ArticleViewWrapperStyled,
    FooterExternalLinkStyled,
    FooterLinkStyled,
    FooterLinkWrapperStyled,
} from '../other-deals-view/styled'
import { ArticleTextStyle, OttoBoxNoBgStyled, ScrollBox } from '../styled'
import { ArticleModalState, cleanUpArticleModal, getArticle } from './articleModalSlice'

interface ArticleModalProperties {
    articleId: string
    returnUrl?: string
    routeUrl?: string
    routeLabel?: string
    onHideCallBack?: () => void
    result?: GlobalSearchResponseDO
}

const FOOTER_HEIGHT = 80

const ArticleModal = ({
    articleId,
    returnUrl,
    routeUrl,
    routeLabel,
    onHideCallBack,
    result,
}: ArticleModalProperties): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const _articleModalSlice: ArticleModalState = useAppSelector((state) => state.articleModalSlice)

    useEffect(() => {
        const abortController = new AbortController()
        dispatch(
            getArticle({
                sessionContext: sessionContext,
                abortController: abortController,
                articleId: articleId,
            })
        )
            .unwrap()
            .then(() => {
                setLoading(false)
            })
            .catch((error: any) => {
                console.error('error', error)
                setLoading(false)
            })

        return () => {
            cancelAbortController(abortController)
            dispatch(cleanUpArticleModal({}))
        }
    }, [articleId])

    RunOnceEffect(() => {
        let root = document.documentElement
        // root.style.setProperty('--modal-width', '700px');
        root.style.setProperty('--modal-width', 'calc(100vw - 100px)')
        root.style.setProperty('--modal-height', 'calc(100vh - 250px)')
        root.style.setProperty('--modal-margin-top', '130px')
    })

    const onHide = () => {
        if (returnUrl) {
            navigate(returnUrl || '')
        } else if (onHideCallBack) {
            onHideCallBack()
        } else {
            dispatch(closeResultDialog({}))
        }
    }

    const renderRoute = (): React.ReactElement => {
        if (!routeUrl) {
            return <></>
        }
        const gotToRoute = () => {
            dispatch(closeResultDialog({}))
            dispatch(closeGlobalSearchResults({}))
            navigate(routeUrl)
        }

        return <GoToRouteStyled onClick={() => gotToRoute()}>{routeLabel}</GoToRouteStyled>
    }

    const renderClosed = (closed: boolean): React.ReactElement => {
        if (!closed) {
            return <></>
        }
        return <ClosedStyled>CLOSED</ClosedStyled>
    }

    const renderDeal = (): React.ReactElement => {
        if (!result) {
            return <></>
        }

        const gotToRoute = () => {
            if (result) {
                dispatch(closeResultDialog({}))
                dispatch(closeGlobalSearchResults({}))
                navigate('/DEAL/' + result.dealId)
            }
        }

        return (
            <ArticleResultWrapperStyled>
                <ResultTitleWrapperStyled
                    onClick={(e) => {
                        gotToRoute()
                    }}
                >
                    {result?.icon && <Icon className={'green-icon'} icon={result?.icon} />}
                    {result?.label && <DealTypeLabel article={{ dealType: result?.label }} />}
                    <ResultTitleStyled dangerouslySetInnerHTML={{ __html: result?.title?.replace(': General', '') || '' }} />
                    {renderClosed(result?.closed || false)}
                </ResultTitleWrapperStyled>
            </ArticleResultWrapperStyled>
        )
    }
    const renderArticle = (): React.ReactElement => {
        const heightAdjustment = result ? 30 : 0
        return (
            <div className={'p-2'}>
                <ArticleInlineDateStyled>
                    {_articleModalSlice?.selectedArticle?.articleDate} - {_articleModalSlice?.selectedArticle?.articleTime || ''}
                </ArticleInlineDateStyled>
                <ScrollBox height={'calc(100vh - ' + (300 + heightAdjustment + FOOTER_HEIGHT) + 'px)'}>
                    <ArticleTextStyle>
                        <WysiwygTableContentStyled
                            dangerouslySetInnerHTML={{
                                __html: _articleModalSlice?.selectedArticle?.content || '',
                            }}
                        ></WysiwygTableContentStyled>
                    </ArticleTextStyle>
                </ScrollBox>
                {renderRoute()}
            </div>
        )
    }
    const renderSpinner = (): React.ReactElement => {
        return <TableSpinner />
    }

    const renderExternalLink = (label: string, link: string): React.ReactElement => {
        const onLinkClick = (e: React.MouseEvent<HTMLDivElement>) => {
            if (link) {
                window.open(link)
            }
        }

        return (
            <ArticleFooterColumnContentsStyled>
                <ArticleFooterColumnDetailsStyled>
                    <ArticleFooterColumnDetailStyled>
                        <FooterExternalLinkStyled onClick={(e: React.MouseEvent<HTMLDivElement>) => onLinkClick(e)}>
                            {label}
                        </FooterExternalLinkStyled>
                    </ArticleFooterColumnDetailStyled>
                </ArticleFooterColumnDetailsStyled>
            </ArticleFooterColumnContentsStyled>
        )
    }

    const renderLink = (label: string, icon: string, link?: string): React.ReactElement => {
        const onLinkClick = (e: React.MouseEvent<HTMLDivElement>) => {
            if (link) {
                window.open(link)
            }
        }

        const iconUrl =
            environmentService.getImageSrc(
                icon,
                environmentService.getEnvironment()?.assetsUrl || '',
                guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,
                true
            ) || ''

        return (
            <FooterLinkWrapperStyled>
                <FooterLinkStyled onClick={(e: React.MouseEvent<HTMLDivElement>) => onLinkClick(e)}>
                    <ImageStyled key={iconUrl} src={iconUrl} width={'15px'} alt={label} />
                    &nbsp;
                    {label}
                </FooterLinkStyled>
            </FooterLinkWrapperStyled>
        )
    }

    const renderEventDriven = (): React.ReactElement => {
        return (
            <ArticleFooterColumnContentsStyled>
                <ArticleFooterColumnHeaderStyled>Event Driven Group</ArticleFooterColumnHeaderStyled>
                <ArticleFooterColumnDetailsStyled>
                    <ArticleFooterColumnDetailStyled>
                        {renderLink('eventdriven@olivetreeglobal.com', 'icons/email-blue.svg', 'mailto:eventdriven@olivetreeglobal.com')}{' '}
                    </ArticleFooterColumnDetailStyled>
                    <ArticleFooterColumnDetailStyled>
                        {renderLink('Level 1, 70 Mark Lane, London, EC3R 7HT', 'icons/address-blue.svg')}{' '}
                    </ArticleFooterColumnDetailStyled>
                </ArticleFooterColumnDetailsStyled>
            </ArticleFooterColumnContentsStyled>
        )
    }

    const renderSales = (): React.ReactElement => {
        return (
            <ArticleFooterColumnContentsStyled>
                <ArticleFooterColumnHeaderStyled position={'center'}>Sales Direct</ArticleFooterColumnHeaderStyled>
                <ArticleFooterColumnDetailsStyled>
                    <ArticleFooterColumnDetailStyled>
                        {renderLink('+44 203 201 1015', 'icons/phone-blue.svg', '+442032011015')}{' '}
                    </ArticleFooterColumnDetailStyled>
                </ArticleFooterColumnDetailsStyled>
            </ArticleFooterColumnContentsStyled>
        )
    }

    const renderResearch = (): React.ReactElement => {
        return (
            <ArticleFooterColumnContentsStyled>
                <ArticleFooterColumnHeaderStyled>Research Direct</ArticleFooterColumnHeaderStyled>
                <ArticleFooterColumnDetailsStyled>
                    <ArticleFooterColumnDetailStyled>
                        {renderLink('+44 203 201 1017', 'icons/phone-blue.svg', 'tel:+44 2032011017')}{' '}
                    </ArticleFooterColumnDetailStyled>
                </ArticleFooterColumnDetailsStyled>
            </ArticleFooterColumnContentsStyled>
        )
    }

    const renderFooter = (): React.ReactElement => {
        return (
            <ArticleFooterGridWrapperStyled>
                <ArticleFooterGridStyled>
                    <ArticleFooterRowStyled>
                        <ArticleFooterColumnStyled position={'left'}>{renderEventDriven()}</ArticleFooterColumnStyled>
                        <ArticleFooterColumnStyled position={'center'}>{renderSales()}</ArticleFooterColumnStyled>
                        <ArticleFooterColumnStyled position={'right'}>{renderResearch()}</ArticleFooterColumnStyled>
                    </ArticleFooterRowStyled>
                </ArticleFooterGridStyled>
            </ArticleFooterGridWrapperStyled>
        )
    }

    const renderFooterDisclaimer = (): React.ReactElement => {
        return (
            <ArticleFooterGridWrapperStyled>
                <ArticleFooterGridStyled>
                    <ArticleFooterRowStyled style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <ArticleFooterColumnStyled position={'left'}>
                            {renderExternalLink('Disclaimer', 'https://www.olivetreeglobal.com/disclaimer/')}
                        </ArticleFooterColumnStyled>
                        <ArticleFooterColumnStyled position={'center'}>
                            {renderExternalLink('Legal & Regulatory', 'https://www.olivetreeglobal.com/legal-regulatory/')}
                        </ArticleFooterColumnStyled>
                        <ArticleFooterColumnStyled position={'right'}>
                            {renderExternalLink('Privacy Notice', 'https://www.olivetreeglobal.com/privace-notice/')}
                        </ArticleFooterColumnStyled>
                    </ArticleFooterRowStyled>
                </ArticleFooterGridStyled>
            </ArticleFooterGridWrapperStyled>
        )
    }

    return (
        <Modal
            dialogAs={DraggableModalDialog}
            centered
            show={true}
            keyboard={true}
            onHide={() => onHide()}
            backdrop={true}
            dialogClassName="modal-wrapper"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <ActionDialogHeaderStyled closeButton={true}>
                <div>
                    <ActionDialogTitleStyled>{_articleModalSlice?.selectedArticle?.subject}</ActionDialogTitleStyled>
                    {renderDeal()}
                </div>
            </ActionDialogHeaderStyled>
            <ArticleViewDialogBodyStyled>
                <ArticleViewWrapperStyled>
                    <OttoBoxNoBgStyled>
                        {!loading && renderArticle()}
                        {loading && renderSpinner()}
                    </OttoBoxNoBgStyled>
                </ArticleViewWrapperStyled>
                <ArticleFooterStyled footerHeight={FOOTER_HEIGHT - 20} style={{ borderTop: 'solid 1px #e8e8e8' }}>
                    {renderFooter()}
                </ArticleFooterStyled>
                <ArticleFooterStyled footerHeight={20} style={{ backgroundColor: '#e8e8e8' }}>
                    {renderFooterDisclaimer()}
                </ArticleFooterStyled>
            </ArticleViewDialogBodyStyled>
        </Modal>
    )
}
export default ArticleModal
