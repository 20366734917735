import React, { useContext, useState } from 'react'
import { SystemContext } from '../../../../../providers/system/SystemContext'
import { fieldService } from '../../../../FieldService'
import { ChildReference, FieldConfigDTO, FieldProperties, FormMode, IMenuDTO } from '../../../../types'

interface TickerLookupProperties {
    code: string
}

export const TickerLookup = (properties: TickerLookupProperties): React.ReactElement<TickerLookupProperties> => {
    const systemContext = useContext(SystemContext)
    const [menuItem, setMenuItem] = useState<IMenuDTO | undefined>(systemContext.getMenuConfig(properties.code))

    const getCurrentRowData = () => {
        return {}
    }
    const registerChildReference = (reference: ChildReference) => {}

    const [fieldProperties] = useState<FieldProperties>(() => {
        const fieldConfig: FieldConfigDTO = {
            name: 'code',
            label: 'code',
            type: 'lookup_modal',
        }
        return {
            code: 'table',
            menuItem: menuItem,
            formMode: FormMode.VIEW,
            isTableView: true,
            fieldConfig: fieldConfig,
            rowData: {},
            inline: false,
            data: '', // changed {} => "" MK 26.1.2025
            onSubmitted: () => {},
            onChange: (name: string, value: any, overwrite?: boolean) => {},
            getCurrentRowData,
            reference: {
                name: fieldConfig.name,
                displayOrder: 1,
                register: registerChildReference,
            },
            // reLoad: reLoad,
            //     uniqueId: rowData?.id,
            // tableCode: tableCode,
            // tableState: tableState,
            // tableRowState: tableRowState
        }
    })

    console.log('properties', properties, menuItem)
    console.log('....  fieldService   ....  ', fieldService.getFormTemplate(fieldProperties))

    return (
        <div>
            <p>ticker lookup</p>
            {/*<div>{fieldService.getFormTemplate(fieldProperties)}</div>*/}
        </div>
    )
}
