import styled, { ThemeProps } from 'styled-components'

export interface TickerFieldProperties extends ThemeProps<any> {
    className: string
    hasMoreData?: boolean
}

export const TickerFieldStyled = styled.div.attrs((props: TickerFieldProperties) => ({
    className: props.className,
}))<TickerFieldProperties>`
    font-size: ${(props) => (props.hasMoreData ? '0.7rem' : 'inherit')};
    height: ${(props) => (props.hasMoreData ? '30px' : 'inherit')};
`

export const TickerSymbolStyled = styled.div.attrs((props: TickerFieldProperties) => ({
    className: props.className,
}))<TickerFieldProperties>`
    // border: ${(props) => (props.hasMoreData ? `1px solid ${props.theme.colors.dark[1]}` : 'none')};
    height: ${(props) => (props.hasMoreData ? '15px' : 'inherit')};
    margin-top: ${(props) => (props.hasMoreData ? '-8px' : 'inherit')};
`

export const TickerPutCallStyled = styled.div.attrs((props: TickerFieldProperties) => ({
    className: props.className,
}))<TickerFieldProperties>`
    position: relative;
    font-size: 0.6rem;
    height: 15px;
    /* Use pseudo-elements for the borders */
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 90%;
        height: 30%;
        width: 1px;
        background-color: ${(props) => props.theme.colors.dark[1]};
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
`
export const TickerOtherStyled = styled.div.attrs((props: TickerFieldProperties) => ({
    className: props.className,
}))<TickerFieldProperties>`
    font-size: 0.6rem;
    height: 15px;
`
