import { isArray } from 'chart.js/helpers'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SystemContext } from '../../../providers/system/SystemContext'
import { GuslThemeContext } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { getSettings, shouldHide, validate } from '../../../services/ValidationService'
import { isDefined, isObject } from '../../../utils/TypeCheckers'
import {
    areFieldsMediaTypeSensitive,
    buildDisplayWhenSelected,
    buildPathFromRef,
    extractExtraFieldProperties,
    hasDisplayWhenSelected,
    matchMediaTypeWithField,
    replaceSpaceWithDash,
    RunOnceEffect,
    safeStream,
    unSubscribe,
} from '../../../utils/Utils'
import { fieldService } from '../../FieldService'
import {
    FieldConfigDTO,
    FieldPositionProperties,
    FieldProperties,
    FilterActionDO,
    FormMode,
    LookupConfigDTO,
    MediaType,
    TableRowDTO,
} from '../../types'
import { handleResize, handleWindowChange, initFieldPositionProperties } from '../../ui/FieldUtils'
import { TableContentStyled, TableHeaderRowStyled } from '../date-summary/styled'
import ElementWithModal from '../element-with/element-with-modal/ElementWithModal'
import { ElementWithTooltip } from '../element-with/element-with-tooltip/ElementWithTooltip'
import FieldLabel from '../field-label/FieldLabel'
import MiniLoadingSpinner from '../gusl-table/table-controls/MiniLoadingSpinner'
import { ImageStyled } from '../image/styled'
import {
    CloseButtonIconStyled,
    CloseButtonStyled,
    ComboWrapperStyled,
    CurrentlySelected,
    DownArrowStyled,
    DownArrowSvgPathStyled,
    DownArrowSvgStyled,
    IndicatorsContainerStyled,
    InputFieldContainerStyled,
    LookupColumnStyled,
    LookupContainerStyled,
    LookupControlContainerStyled,
    LookupHeaderColumnStyled,
    LookupTableBodyStyled,
    LookupTableContainerStyled,
    LookupTableHeadStyled,
    LookupTableResponsiveStyled,
    LookupTableRowStyled,
    LookupTableStyled,
    LookupTableViewStyled,
    LookupValueStyled,
    MatchesContentStyled,
    MatchesStyled,
    NoMatchesContentStyled,
    NoMatchStyled,
    NoneCurrentlySelected,
    SelectedFormValueStyled,
    SeparatorStyled,
} from '../lookup/styled'
import { CurrentMatchesDO } from '../lookup/types'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { OptionFieldOpenState, toggleOptionFieldOpen } from '../option/optionFieldSlice'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled, TextFieldStyled } from '../text/styled'
import { LookupModalContent } from './LookupModalContent'

export const LookupModal = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const systemContext = useContext(SystemContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const dispatch = useAppDispatch()

    const [className] = React.useState<string>(() => 'LookupField_' + properties.fieldConfig.name + '_' + new Date().getTime())
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const optionFieldOpenState: OptionFieldOpenState = useAppSelector((state) => state.optionFieldOpenSlice)
    const inputRef = useRef<HTMLInputElement>(null)
    let matches: any[] = []

    const [searchValue, setSearchValue] = useState<string>(inputRef?.current?.value || '')

    const [formMode, setFormMode] = useState(properties.formMode)
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')
    const [multiRowFormValue, setMultiRowFormValue] = useState<string[]>(
        _guslFormState?.getFieldValue(properties) || properties.data || ['']
    )
    const [multiRowData, setMultiRowData] = useState<any[]>([])

    const [refreshRequest, setRefreshRequest] = useState<number>(0)

    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType())
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [cursor, setCursor] = useState<number>(-1)

    const [statusClassName, setStatusClassName] = useState<string>('')

    const lookupElement = useRef(null)
    const [filterActions, setFilterActions] = useState<FilterActionDO[]>([])
    const [currentFilterAction, setCurrentFilterAction] = useState<FilterActionDO | undefined>(undefined)

    const [oldResize, setOldResize] = useState<number | undefined>(undefined)
    const [fieldPositionProperties, setFieldPositionProperties] = useState<FieldPositionProperties>(() =>
        initFieldPositionProperties(environmentContext, properties)
    )

    const [loading, setLoading] = useState<boolean>(false)
    const showCurrentlyArrowed: boolean = false // lookupData.length > 0 && cursor >= 0 && !formValue;
    const serverSearch: boolean = typeof properties?.fieldConfig?.lookupSearchUrl !== 'undefined'
    const blastSearch: boolean = properties?.fieldConfig?.lookupCollection !== ''
    const referenceName = properties?.reference?.name
    const matchesWrapperId = 'matches_' + referenceName

    const [matchesOpen, setMatchesOpen] = useState<boolean>(
        optionFieldOpenState?.id ? optionFieldOpenState.id === properties.fieldConfig.name : false
    )

    // const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties));

    const [multiSelect, setMultiSelect] = useState<boolean>(false) // extraFieldProperties?.multiSelect || false);

    // document.documentElement.style.setProperty(`--bs-modal-width`, '1101px !important');

    const [createCode] = useState<string | undefined>(() => {
        const extraFieldProperties = extractExtraFieldProperties(properties)
        return extraFieldProperties.lookupModalCreateCode
    })

    useEffect(() => {
        return () => {
            console.log('LookupModal Bye ', new Date())
        }
    }, [className])

    useEffect(() => {
        const openState = optionFieldOpenState?.id ? optionFieldOpenState.id === properties.fieldConfig.name : false
        if (openState !== matchesOpen) {
            setMatchesOpen(openState)
        }
    }, [optionFieldOpenState])

    const resetOverwriteFormData = () => {
        if (!properties.fieldConfig.lookupOverwritesForm) {
            return
        }
        const data: any = {}

        safeStream(lookupConfig?.tableFields).forEach((fld) => {
            data[fld] = undefined
        })
        properties.onChange(properties.fieldConfig.name, data, true)
    }

    const startAgainHandler = () => {
        setFormValue('')
        setTableValue('')
        setFormValue('')
        setMultiRowFormValue([''])
        setMultiRowData([])
        setSearchValue('')
        setErrorMessage('')
        dispatch(toggleOptionFieldOpen({ id: undefined }))
        setCursor(-1)
        if (inputRef?.current?.value) {
            inputRef.current.value = ''
        }
        if (properties.setTicker) {
            properties.setTicker('')
        }
        resetOverwriteFormData()
    }

    useEffect(() => {
        if (properties.reset) {
            startAgainHandler()
        }
    }, [properties.reset])

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        setErrorMessage(undefined)
        const hideField = shouldHide(formMode, properties.fieldConfig, fieldValue)
        if (!hideField) {
            const message = validate(
                properties.menuItem?.code,
                properties.fieldConfig.name,
                properties.fieldConfig.validators,
                'text',
                fieldValue
            )
            if (message) {
                setErrorMessage(message)
                return false
            }
        }
        return true
    }

    RunOnceEffect(() => {
        // init
        properties.reference.changeMode = onFormModeChange
        properties.reference.doRefresh = onRefresh
        properties.reference.doValidation = doValidation
        properties.reference.register(properties.reference)
        return () => {
            // clean up
        }
    })

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
    }

    const onRefresh = () => {
        startAgainHandler()
    }
    const [lookupData, setLookupData] = useState<any[]>([])

    const [lookUpClosed, setLookUpClosed] = useState<boolean>(false)
    const [lookupHasFields, setLookupHasFields] = useState<boolean>(false)
    const [lookupFields, setLookupFields] = useState<FieldConfigDTO[]>([])
    const [lookupConfig, setLookupConfig] = useState<LookupConfigDTO | undefined>(() => {
        return {
            name: 'lookup',
            extractLabel: 'return `${data.code}` ',
            tableFields: ['code'],
            fieldTypes: ['text'],
            sortBy: 'code',
            pageable: false,
            retValue: 'return data',
        } as LookupConfigDTO
    })

    RunOnceEffect(() => {
        const subscriptionResize = handleResize(
            environmentContext,
            lookupElement,
            oldResize,
            fieldPositionProperties,
            setFieldPositionProperties,
            setOldResize
        )
        return () => {
            unSubscribe(subscriptionResize)
        }
    })

    useEffect(() => {
        handleWindowChange(lookupElement, fieldPositionProperties, setFieldPositionProperties)
    }, [loading, formMode])

    const updateStatusClass = (value: string | undefined): void => {
        let extraClass = ''
        if (value) {
            try {
                // if (canColorise(properties.fieldConfig)) {
                extraClass +=
                    ' ' +
                    replaceSpaceWithDash((properties?.fieldConfig?.name || '').toLowerCase()) +
                    '-badge ' +
                    replaceSpaceWithDash((properties?.fieldConfig?.name || '').toLowerCase()) +
                    '-' +
                    (replaceSpaceWithDash(value?.toLowerCase()) || '') +
                    ' '
                // }
                // if (canColoriseText(properties.fieldConfig)) {
                extraClass +=
                    replaceSpaceWithDash((properties?.fieldConfig?.name || '').toLowerCase()) +
                    '-text-' +
                    (replaceSpaceWithDash(value?.toLowerCase()) || '') +
                    ' '
                // }
            } catch (error) {
                // ignore
            }
        }
        setStatusClassName(extraClass)
    }

    const formatLookupRow = (lookupCfg: LookupConfigDTO | undefined, rowData: any): string => {
        if (!lookupCfg || !rowData || !lookupCfg.extractLabel) {
            return ''
        }

        try {
            const func = new Function('self', 'data', lookupCfg.extractLabel)
            // log.debug(className, 'MSG001', {func})
            // const val = func(this, convertData(rowData));
            const val = func(this, rowData)

            if (val === 'undefined' || !val) {
                return ''
            }
            return val
        } catch (error) {
            log.error(className, 'ERR001', 'Error extracting lookup value', rowData, error)
            return ''
        }
    }

    const extractValue = (rowData?: any): [string | undefined, string] => {
        if (hasDisplayWhenSelected(properties?.fieldConfig?.innerFields)) {
            return buildDisplayWhenSelected(properties?.fieldConfig?.innerFields, rowData || properties.data)
        } else {
            return [undefined, extractValueFromLookupConfig()]
        }
    }

    const extractValueFromLookupConfig = (): string => {
        if (properties?.fieldConfig?.lookup) {
            const lookupCfg: LookupConfigDTO | undefined = maintainTableService.findLookupConfig(
                properties?.fieldConfig?.lookup,
                systemContext?.getSystemConfig()?.lookups || []
            )
            if (lookupCfg) {
                if (lookupCfg.fields) {
                    setLookupHasFields(true)
                    const mediaTypeSensitive = areFieldsMediaTypeSensitive(lookupCfg?.fields || [])
                    setLookupFields(
                        lookupCfg?.fields
                            ?.filter((field) => matchMediaTypeWithField(field, mediaType, mediaTypeSensitive))
                            .filter((field) => field.displayInTable)
                    )
                }
                setLookupConfig(lookupCfg)
                try {
                    const formatted = isObject(properties.data) ? formatLookupRow(lookupCfg, properties.data) : properties.data
                    updateStatusClass(formatted)
                    return formatted
                } catch (err) {
                    console.log('Error setting table value', err, properties.data)
                }
            }
        }
        updateStatusClass(properties?.data?.code || properties?.data || '')
        return properties?.data?.code || properties?.data || ''
    }

    const [tableValue, setTableValue] = useState<string>(() => {
        const [imageUrl, formattedValue] = extractValue()
        return formattedValue
    })

    const [imageUrl, setImageUrl] = useState<string | undefined>(() => {
        const [imageUrl] = extractValue()
        if (!imageUrl) {
            return undefined
        }
        return (
            environmentService.getImageSrc(
                imageUrl || '',
                environmentService.getEnvironment()?.assetsUrl || '',
                guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,
                true
            ) || ''
        )
    })

    const renderImage = (): React.ReactElement => {
        if (!imageUrl) {
            return <></>
        }
        return <ImageStyled src={imageUrl} alt={'logo'} width={'20px'} />
    }

    const renderTableView = (): React.ReactElement => {
        const onFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
            const linkPath = buildPathFromRef(properties?.fieldConfig, properties.rowData)
            if (linkPath && e) {
                e.stopPropagation()
                window.open(linkPath)
                // navigate(linkPath)
            }
        }

        const hasRef: boolean = isDefined(properties?.fieldConfig?.ref)

        return (
            <TextFieldStyled
                hasLink={hasRef}
                cellWidth={100}
                onClick={(e) => {
                    onFieldClick(e)
                }}
                className={statusClassName}
            >
                {renderImage()} {tableValue}
            </TextFieldStyled>
        )
    }

    const showLookupDataHandler = () => {
        // if (!lookupData.length || blastSearch) {
        //     getLookupData();
        // }
        dispatch(
            toggleOptionFieldOpen({
                id: optionFieldOpenState.id === properties.fieldConfig.name ? undefined : properties.fieldConfig.name,
            })
        )
        setSearchValue('')
        setErrorMessage('')

        if (inputRef?.current?.value) {
            inputRef.current.value = ''
        }
    }

    const renderDownArrow = (): React.ReactElement => {
        // const matchesOpen = optionFieldOpenState.id ? optionFieldOpenState.id === properties.fieldConfig.name : false;
        return (
            <ElementWithTooltip
                element={
                    <IndicatorsContainerStyled isActive={matchesOpen} onClick={showLookupDataHandler}>
                        <SeparatorStyled />
                        <DownArrowStyled>
                            <DownArrowSvgStyled>
                                <DownArrowSvgPathStyled />
                            </DownArrowSvgStyled>
                        </DownArrowStyled>
                    </IndicatorsContainerStyled>
                }
                tooltip={<span>{matchesOpen ? 'Close' : 'Open'}</span>}
            />
        )
    }

    const StartAgain = (): React.ReactElement => {
        return (
            <ElementWithTooltip
                element={<i className={'fa-solid fa-xmark ms-3 me-1'} onClick={startAgainHandler} />}
                tooltip={<span>Cancel / Start again</span>}
            />
        )
    }

    const ErrorMessage = (): React.ReactElement => {
        if (searchValue.length > 1 && !matches.length) {
            return (
                <NoMatchesContentStyled {...fieldPositionProperties} isResultTable={true}>
                    <NoMatchStyled>
                        No matches
                        <StartAgain />
                    </NoMatchStyled>
                    <NoMatchStyled>Please contact the desk to place order</NoMatchStyled>
                </NoMatchesContentStyled>
            )
        } else {
            return <></>
        }
    }

    const renderCloseButton = (): React.ReactElement => {
        const onCloseClick = () => {
            dispatch(toggleOptionFieldOpen({ id: undefined }))
            setSearchValue('')
            setErrorMessage('')
            setFormValue('')
            setMultiRowFormValue([''])
            setMultiRowData([])
            setTableValue('')
            setImageUrl(undefined)

            if (inputRef?.current?.value) {
                inputRef.current.value = ''
            }
        }
        return (
            <CloseButtonStyled id={'lookup_matches_button_' + properties.code}>
                <CloseButtonIconStyled onClick={() => onCloseClick()} />
            </CloseButtonStyled>
        )
    }

    const renderResultsHeader = (): React.ReactElement => {
        const mediaTypeSensitive = areFieldsMediaTypeSensitive(lookupConfig?.fields || [])
        return (
            <TableHeaderRowStyled key={'hdr_row'} id={'hdr_row'}>
                {!lookupHasFields &&
                    lookupConfig?.tableFields.map((field, idx) => (
                        <LookupHeaderColumnStyled bold={true} key={'hdr_row_' + idx}>
                            {field}
                        </LookupHeaderColumnStyled>
                    ))}
                {lookupHasFields &&
                    lookupFields.map((field, idx) => (
                        <LookupHeaderColumnStyled bold={true} textAlign={field.align} id={'hdr_rowx_' + idx} key={'hdr_rowx_' + idx}>
                            {field.label}
                            {lookupFields.length === idx + 1 && renderCloseButton()}{' '}
                        </LookupHeaderColumnStyled>
                    ))}
            </TableHeaderRowStyled>
        )
    }

    const rowSelected = (rowData: any) => {
        if (properties.setTicker && rowData && rowData.id) {
            properties.setTicker({
                id: rowData.id,
                name: rowData.name,
                symbol: rowData.symbol,
            })
        }
        const [imageUrl, formattedValue] = extractValue(rowData)
        if (multiSelect) {
            setMultiRowFormValue([formattedValue, ...multiRowFormValue])
            setMultiRowData([rowData, ...multiRowData])
            properties.onChange(properties.fieldConfig.name, [rowData, ...multiRowData], properties.fieldConfig.lookupOverwritesForm)
        } else {
            setFormValue(formattedValue)
            properties.onChange(properties.fieldConfig.name, rowData, properties.fieldConfig.lookupOverwritesForm)
            dispatch(toggleOptionFieldOpen({ id: undefined }))
        }
        setTableValue(formattedValue)
        setImageUrl(imageUrl)

        // setFormValue(formatLookupRow(lookupConfig, rowData));
        // setTableValue(formatLookupRow(lookupConfig, rowData));

        updateStatusClass(formattedValue)
    }

    const renderResultRow = (rowData: any, idx: number): React.ReactElement => {
        // const mediaTypeSensitive = areFieldsMediaTypeSensitive(lookupConfig?.fields || []);
        return (
            <LookupTableRowStyled
                role={'button'}
                key={'id_' + idx}
                id={'id_' + idx}
                active={idx === cursor}
                lookupField={true}
                onClick={() => rowSelected(rowData)}
                // onMouseEnter={() => setHovered(rowData)}
                // onMouseLeave={() => setHovered(undefined)}
            >
                {!lookupHasFields &&
                    (lookupConfig?.tableFields || []).map((field, idx) => (
                        <LookupColumnStyled textAlign={'left'} key={'hdr_row_' + idx}>
                            {rowData[field]}
                        </LookupColumnStyled>
                    ))}
                {lookupHasFields &&
                    lookupFields
                        .filter((field) => field.displayInTable)
                        .map((field, idx) => (
                            <LookupColumnStyled textAlign={field.align} key={'hdr_row_' + idx}>
                                {' '}
                                {fieldService.getTableField(field, undefined, rowData)?.render()}
                            </LookupColumnStyled>
                        ))}
            </LookupTableRowStyled>
        )
    }

    const renderResultRows = (rowData: any[]) => {
        return <>{rowData && isArray(rowData) && rowData.map((row, idx) => renderResultRow(row, idx))}</>
    }

    const renderResults = (rowData: any[]): React.ReactElement => {
        return (
            <LookupTableContainerStyled id={'lookup_results_tcs_' + properties.code}>
                <TableContentStyled id={'lookup_results_tcontent_' + properties.code}>
                    <LookupTableResponsiveStyled className={'tbl_content'}>
                        <LookupTableStyled>
                            <LookupTableHeadStyled>{renderResultsHeader()}</LookupTableHeadStyled>
                            <LookupTableBodyStyled id={matchesWrapperId} tBodyHeight={200} isOverFlown={false}>
                                {renderResultRows(rowData)}
                            </LookupTableBodyStyled>
                        </LookupTableStyled>
                    </LookupTableResponsiveStyled>
                </TableContentStyled>
            </LookupTableContainerStyled>
        )
    }

    function CurrentMatches({ collection }: CurrentMatchesDO) {
        return (
            <ComboWrapperStyled {...fieldPositionProperties}>
                <MatchesStyled id={'lookup_matches_ms_' + properties.code}>{renderResults(collection)}</MatchesStyled>
            </ComboWrapperStyled>
        )
    }

    const Matches = () => {
        if (searchValue.length > (serverSearch ? 1 : 0)) {
            matches = serverSearch
                ? lookupData
                : lookupData.filter((item) => {
                      let found = false
                      for (const key in item) {
                          if (item.hasOwnProperty(key)) {
                              if (item[key]) {
                                  try {
                                      if (item[key].toLowerCase().includes(searchValue.toLowerCase())) {
                                          found = true
                                      }
                                  } catch (err) {
                                      // ignore
                                  }
                              }
                          }
                      }
                      return found
                  })
        }
        // const matchesOpen = optionFieldOpenState.id ? optionFieldOpenState.id === properties.fieldConfig.name : false;

        if (loading) {
            return <MiniLoadingSpinner isLoading={loading} />
        } else if (errorMessage) {
            return (
                <NoMatchStyled>
                    {errorMessage} <StartAgain />
                </NoMatchStyled>
            )
        } else if (matches.length && !formValue) {
            return (
                <MatchesContentStyled {...fieldPositionProperties}>
                    <CurrentMatches collection={matches} />
                </MatchesContentStyled>
            )
        } else if (matchesOpen) {
            return (
                <MatchesContentStyled {...fieldPositionProperties} isResultTable={true}>
                    <CurrentMatches collection={lookupData} />
                </MatchesContentStyled>
            )
        } else {
            return <></>
        }
    }

    const FormViewMode = (): React.ReactElement => {
        return (
            <FloatingFormStyled key={'id_' + refreshRequest}>
                <FieldContentWrapperStyled>
                    <FieldContentStyled>
                        <LookupValueStyled>{renderTableView()}</LookupValueStyled>
                    </FieldContentStyled>
                    {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                </FieldContentWrapperStyled>
                <FieldLabel properties={properties} />
            </FloatingFormStyled>
        )
    }

    const renderCurrentlyArrowed = (): React.ReactElement => {
        if (showCurrentlyArrowed) {
            return (
                <CurrentlySelected key={'lm_curr_selected'}>
                    {lookupData[cursor].id}
                    <br /> <span>Press enter or click to select</span>
                </CurrentlySelected>
            )
        } else {
            return <NoneCurrentlySelected key={'lm_none_selected'} />
        }
    }

    const renderFormValue = (): React.ReactElement => {
        if (multiSelect) {
            return (
                <SelectedFormValueStyled>
                    {multiRowFormValue.map((selectedRow: string, idx: number) => (
                        <SelectedFormValueStyled>
                            <LookupTableViewStyled key={'s_' + idx}> {selectedRow} </LookupTableViewStyled>
                        </SelectedFormValueStyled>
                    ))}
                </SelectedFormValueStyled>
            )
        } else if (formValue) {
            return (
                <SelectedFormValueStyled>
                    <LookupTableViewStyled>
                        {renderImage()} {tableValue}{' '}
                    </LookupTableViewStyled>
                    <StartAgain />
                </SelectedFormValueStyled>
            )
            // } else {
            //     return (
            //         <LookupInputStyled onInput={inputHandler}
            //                            id={"lookup_li_" + properties.code}
            //                            ref={inputRef}
            //                            type="text"
            //                            defaultValue={formValue ? formValue : ""}
            //                            placeholder={'Click for options'}/>
            //     )
            // }
        }
        return <></>
    }

    const renderCurrentValue = (): React.ReactElement => {
        return (
            <LookupContainerStyled submitted={submitted} noValue={!formValue} id={'lookup_lc_' + properties.code}>
                <LookupControlContainerStyled
                    id={'lookup_cc_' + properties.code}
                    {...fieldPositionProperties}
                    hasLabel={properties.hasLabel}
                >
                    <InputFieldContainerStyled id={'lookup_ifc_' + properties.code}>{renderFormValue()}</InputFieldContainerStyled>
                    {renderDownArrow()}
                </LookupControlContainerStyled>
            </LookupContainerStyled>
        )
    }

    const FormValueEditMode = (): React.ReactElement => {
        return (
            <FloatingFormStyled key={'lookup_ff_' + properties.code} id={'lookup_ff_' + properties.code} ref={lookupElement}>
                <FieldContentWrapperStyled id={'lookup_modal_fcw'} {...fieldPositionProperties} isLookup={true}>
                    <FieldContentStyled id={'lookup_modal_ffcs_' + properties.code}>
                        {renderCurrentlyArrowed()}
                        {renderCurrentValue()}
                    </FieldContentStyled>
                    {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                    {submitted && !formValue && <small className="invalid">{properties?.fieldConfig?.label || ''} is required.</small>}
                </FieldContentWrapperStyled>
                <FieldLabel properties={properties} />
            </FloatingFormStyled>
        )
    }

    const FormEditMode = (): React.ReactElement => {
        const onMultiRowSelected = (rowData: TableRowDTO[]) => {
            const formattedRows: string[] = []
            safeStream(rowData).forEach((row) => {
                const [imageUrl, formattedValue] = extractValue(row)
                formattedRows.push(formattedValue)
            })
            setMultiRowFormValue(formattedRows)
            setMultiRowData([rowData])
            properties.onChange(properties.fieldConfig.name, [rowData, ...multiRowData], properties.fieldConfig.lookupOverwritesForm)
        }

        const onRowSelected = (rowData: TableRowDTO) => {
            if (properties.setTicker && rowData && rowData.id) {
                properties.setTicker({
                    id: rowData.id,
                    name: rowData?.name || '',
                    symbol: rowData?.symbol || '',
                })
            }

            const [imageUrl, formattedValue] = extractValue(rowData)
            const imageFullUrl =
                environmentService.getImageSrc(
                    imageUrl || '',
                    environmentService.getEnvironment()?.assetsUrl || '',
                    guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,
                    true
                ) || ''

            console.log('formattedValue', formattedValue)

            setFormValue(formattedValue)
            setTableValue(formattedValue)
            setImageUrl(imageFullUrl)

            properties.onChange(properties.fieldConfig.name, rowData, properties.fieldConfig.lookupOverwritesForm)
            dispatch(toggleOptionFieldOpen({ id: undefined }))
            updateStatusClass(formattedValue)
        }
        const onCloseHandler = () => {
            dispatch(toggleOptionFieldOpen({ id: undefined }))
        }

        return (
            <ElementWithModal
                code={properties.fieldConfig.name}
                modalClassName={'lookup_modal_' + properties.fieldConfig.name}
                key={'lookup_modal_' + properties.fieldConfig.name}
                open={matchesOpen}
                withCancel={true}
                withOkay={multiSelect}
                hideHeader={true}
                centered={true}
                onCloseHandler={onCloseHandler}
                element={<FormValueEditMode key={'two_' + properties.fieldConfig.name} />}
                modalContent={
                    <LookupModalContent
                        key={'content_' + properties.fieldConfig.name}
                        {...properties}
                        code={properties.fieldConfig.name}
                        multiSelect={multiSelect}
                        fieldPositionProperties={fieldPositionProperties}
                        createCode={createCode}
                        onRowSelected={(row: TableRowDTO) => onRowSelected(row)}
                        onMultiRowSelected={(row: TableRowDTO[]) => onMultiRowSelected(row)}
                    />
                }
            />
        )
    }
    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)
        if (hideField || !_guslFormState) {
            return <></>
        }
        if (formMode === FormMode.VIEW || disableField) {
            return <FormViewMode />
        }
        return <FormEditMode key={'form-edit-mode_' + properties.fieldConfig.name} />
    }

    return (
        <div key={'lookup_modal_wrapper_' + properties.fieldConfig.name}>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </div>
    )
}
