import React from 'react'
import { FieldProperties } from '../../types'
import { ListViewTickerDO } from '../buy-sell-form/types'
import { TickerFieldStyled, TickerOtherStyled, TickerPutCallStyled, TickerSymbolStyled } from './styled'

export const TickerField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const ticker: ListViewTickerDO = properties.data
    const hasMoreData: boolean = typeof ticker.putcall !== 'undefined' && typeof ticker.strikePrice !== 'undefined'

    return (
        <>
            {properties.isTableView ? (
                <TickerFieldStyled className={'row g-0'} hasMoreData={hasMoreData}>
                    <TickerSymbolStyled hasMoreData={hasMoreData} className={'col-12  text-center'}>
                        {ticker.symbol}
                    </TickerSymbolStyled>
                    {ticker.strikePrice && ticker.putcall && (
                        <>
                            <TickerOtherStyled hasMoreData={hasMoreData} className="col-4  text-center">
                                {ticker.del}
                            </TickerOtherStyled>
                            <TickerPutCallStyled hasMoreData={hasMoreData} className="col-4  text-center">
                                {ticker.putcall}
                            </TickerPutCallStyled>
                            <TickerOtherStyled hasMoreData={hasMoreData} className="col-4  text-center">
                                {ticker.strikePrice}
                            </TickerOtherStyled>
                        </>
                    )}
                </TickerFieldStyled>
            ) : (
                <></>
            )}
        </>
    )
}
