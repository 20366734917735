import { Modal } from 'react-bootstrap'
import styled, { ThemeProps } from 'styled-components'

export const OtherDealGrid = styled.div.attrs((props) => ({
    className: 'g-0',
    id: 'other-deals-grid',
}))``
export const ArticleViewDialogBodyStyled = styled(Modal.Body).attrs((props) => ({
    id: 'article_view_dialog_body',
}))`
    background-color: #fff;
    padding: 0 !important;
    //max-height: 700px;
    overflow: auto;
    display: grid;
`
export const ArticleViewWrapperStyled = styled.div.attrs((props) => ({
    id: 'article-wrapper',
}))`
    padding: 5px;
    background-color: #ffffff;
`

interface ArticleFooterProperties {
    footerHeight: number
}
export const ArticleFooterStyled = styled.div.attrs((props: ArticleFooterProperties) => ({
    id: 'article-footer',
}))<ArticleFooterProperties>`
    padding: 0;
    background-color: #f7f5f5;
    min-height: ${(props) => props.footerHeight + 'px'};
`

export const ArticleFooterGridWrapperStyled = styled.div.attrs((props) => ({
    id: 'article-footer-grid-wrapper',
}))``

export const ArticleFooterGridStyled = styled.div.attrs((props) => ({
    className: 'g-0',
    id: 'article-footer-grid',
}))`
    //width:100%;
    margin-top: 5px;
    max-width: fit-content;
    margin-inline: auto;
    //@media (min-width: 992px) {
    //    width: 900px;
    //}
`
export const ArticleFooterRowStyled = styled.div.attrs((props) => ({
    className: 'row g-0',
    id: 'article-footer-row',
}))``

interface ArticleFooterColumnProperties {
    position: 'left' | 'right' | 'center'
}
export const ArticleFooterColumnStyled = styled.div.attrs((props: ArticleFooterColumnProperties) => ({
    className: 'col-lg-4 ',
    id: 'article-footer-col',
}))<ArticleFooterColumnProperties>`
    min-width: 300px;

    @media (min-width: 992px) {
        ${(props) => props.position === 'center' && 'text-align: center;'};
        ${(props) => props.position === 'right' && 'text-align: center;'};
    }
`
export const ArticleFooterColumnContentsStyled = styled.div.attrs((props) => ({
    id: 'article-footer-col-contents',
}))`
    @media (max-width: 992px) {
        margin-top: 10px;
    }
`

interface ArticleFooterColumnHeaderProperties {
    position?: 'left' | 'right' | 'center'
}

export const ArticleFooterColumnHeaderStyled = styled.div.attrs((props: ArticleFooterColumnHeaderProperties) => ({
    id: 'article-footer-col-header',
}))<ArticleFooterColumnHeaderProperties>`
    color: #000000;
    font-size: 14px;
    margin-left: 5px;

    @media (min-width: 992px) {
        ${(props) => props.position === 'center' && 'position: relative;left: -15px'};
    }
`

export const ArticleFooterColumnDetailsStyled = styled.div.attrs((props) => ({
    id: 'article-footer-col-details',
}))``

export const ArticleFooterColumnDetailStyled = styled.div.attrs((props) => ({
    id: 'article-footer-col-detail',
}))``

export const SearchWrapperStyled = styled.div.attrs((props) => ({
    id: 'search-wrapper',
}))`
    width: 35%;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
`

interface IconsStyledProps extends ThemeProps<any> {
    width: string
}

export const IconsStyled = styled.span.attrs((props: IconsStyledProps) => ({}))<IconsStyledProps>`
    width: ${(props) => props.width};
    margin-right: 5px;
`

export const FooterLinkWrapperStyled = styled.div.attrs((props) => ({}))`
    color: #000;
    margin: 5px;
`
export const FooterLinkStyled = styled.div.attrs((props) => ({}))`
    font-size: 10px;
    margin: 2px;
    color: #1890ff;
    &:hover {
        text-decoration: underline;
    }
`

export const FooterExternalLinkStyled = styled.div.attrs((props) => ({}))`
    font-size: 10px;
    margin: 2px;
    color: #1890ff;
    &:hover {
        text-decoration: underline;
    }
`
